<template>
  <!-- Name -->
  <InputField :field-title="$t('CLASSES_HELPDESK.CLASS_NAME')"
              :field-name="'name'"
              :rules="'required'"
              :type="'text'"
              :value="originalName"
              :placeholder="$t('EDIT_CLASS.NAME_PLACEHOLDER')"
              :cy-selector="'name-input'"
              :input-icon="require('../../../assets/icons/icn_class.svg')"
              :input-icon-error="require('../../../assets/icons/icn_error.svg')"
              :api-error-text="nameInUseError"
              :on-update="handleNewInput"
              class="form-element">
    <template #extra-content>
      <div class="tooltip">
        <InfoTooltip :text="$t('CREATE_CLASS_TOOLTIP')" />
      </div>
    </template>
  </InputField>
</template>

<script>
import InputField from '@/components/yo-form/InputField'
import { computed, ref } from '@vue/runtime-core'
import nl from '@/utils/language/nl.json'
import { useStore } from 'vuex'
import { GET_CLASS_NAME_AVAILABLE } from '@/store/actions'
import InfoTooltip from '@/components/elements/InfoTooltip'

export default {
  name: 'InputClassName',
  components: {
    InputField,
    InfoTooltip,
  },
  props: {
    onInput: {
      type: Function,
      default: () => {}
    },
    accepted: {
      type: Function,
      default: () => {}
    },
    originalName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const available = ref(true)
    const store = useStore()
    const nameInUseError = computed(() => !available.value ? nl.CREATE_CLASS.NAME_IN_USE : '')

    function handleNewInput(name) {
      available.value = true
      props.onInput(name)
      props.accepted(true)
      bounceCheck(name)
    }

    let timerId


    function bounceCheck(name) {
      clearTimeout(timerId)
      if (name === props.originalName) {
        available.value = true
        props.accepted(true)
        return
      }
      timerId = setTimeout(() => checkAvailable(name), 1000)
    }

    function checkAvailable(name) {
      store.dispatch(GET_CLASS_NAME_AVAILABLE, name).then(result => {
        available.value = result
        props.accepted(result)
      })
    }


    return {
      nameInUseError,
      handleNewInput,
    }
  }
}
</script>

<style scoped>
  .tooltip {
    position: absolute;
    top: 52px;
    right: -32px;
    transform: translateY(-50%);
  }
</style>
